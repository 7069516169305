/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var SKYWIRE = SKYWIRE || {};

SKYWIRE.config = {
    media_match_mobile_to_tablet : 'screen and (max-width: 740px)',
    media_match_tablet_to_desktop : 'screen and (min-width: 740px) and (max-width: 979px)',
    media_match_tablet_and_up : 'screen and (min-width: 740px)',
    media_match_desktop_and_up : 'screen and (min-width: 980px)',
    media_match_desktop_and_down : 'screen and (max-width: 1023px)',
    media_match_wide_and_down : 'screen and (max-width: 1280px)',
    media_match_wide_and_up : 'screen and (min-width: 1281px)'
};


(function($) {

    function navigation(mql) {
        if (mql.matches) {
            if ($('.nav-primary__wrapper').attr('style')) {
                $('.nav-primary__wrapper').removeAttr('style');
            }
        }
    }

    function stickyShare(mql) {
        if (mql.matches) {
            var target = $('.social-sidebar'),
                offset = target.offset(),
                topPosition = 20,
                leftPosition = 40;

            if (target.length > 0) {

                if ($(window).width() < 980) {
                    leftPosition = 25;
                }

                $(window).on('scroll', function () {
                    if ($(window).scrollTop() >= offset.top) {
                        target.css({'position': 'fixed', top: topPosition, left: leftPosition});
                    } else {
                        target.removeAttr('style');
                    }
                });
            }
        }
    }
    
    function init() {
        window.matchMedia(SKYWIRE.config.media_match_tablet_and_up).addListener(navigation);
    }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
    // All pages
    'common': {
        init: function() {
            // JavaScript to be fired on all pages

            var menuButton = $('.burger');
            menuButton.on('click', function () {
                if (!$(this).hasClass('active')) {
                    $(this).addClass('active');
                    $('.nav-primary__wrapper').show();
                } else {
                    $(this).removeClass('active');
                    $('.nav-primary__wrapper').hide();
                }
            });

            init();
            navigation(window.matchMedia(SKYWIRE.config.media_match_tablet_and_up));

            $('.back-top__trigger').click(function (e) {
                e.preventDefault();

                $('html, body').animate({
                    scrollTop: $($(this).attr('href')).offset().top
                }, 1000);
            });

            $('.post-block__image img').hover(function () {
                $(this).toggleClass('over');
            });

            $('.dotMailer_news_letter').on('submit', function (e) {
                if ($('#dotMailer_email').val().length === 0) {
                    e.preventDefault();
                }
            });

        },
        finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        }
    },
    'single': {
        init: function() {
            stickyShare(window.matchMedia(SKYWIRE.config.media_match_tablet_and_up));
        },
        finalize: function () {
            window.matchMedia(SKYWIRE.config.media_match_tablet_and_up).addListener(stickyShare);
        }
    },
    // Home page
    'home': {
        init: function() {
        // JavaScript to be fired on the home page
        },
        finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        }
    },
    // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
            // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
    fire: function(func, funcname, args) {
        var fire;
        var namespace = Sage;
        funcname = (funcname === undefined) ? 'init' : funcname;
        fire = func !== '';
        fire = fire && namespace[func];
        fire = fire && typeof namespace[func][funcname] === 'function';

        if (fire) {
        namespace[func][funcname](args);
        }
    },
    loadEvents: function() {
        // Fire common init JS
        UTIL.fire('common');

        // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
        });

        // Fire common finalize JS
        UTIL.fire('common', 'finalize');
    }
  };

// Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
